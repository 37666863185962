import React, { useEffect } from "react";
import { FC, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Divider, Drawer, Hidden, List, ListSubheader, makeStyles } from "@material-ui/core";
import {
  Disc as DiscIcon,
  Activity as ActivityIcon,
  Mail as MailIcon,
  Users as UsersIcon,
  CreditCard as CreditCardIcon,
  TrendingUp as TrendingUpIcon,
  DollarSign,
  Columns,
  Tag,
  List as ListIcon,
  Calendar,
  Settings,
  Percent
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import { getCurrentUserAccountId, useCurrentUserTeamsAndOrgs } from "../../hooks/commonDataUtils";
import { compute } from "@ollie-sports/core";
import { translate } from "@ollie-sports/i18n";
import { ReceiptOutlined } from "@material-ui/icons";
import {
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  TagIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon
} from "@heroicons/react/24/outline";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

enum ShowPermission {
  isOrgAdmin = "isOrgAdmin"
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  showItem: boolean;
}

interface Section {
  items: Item[];
  subheader: string;
  showSection: boolean;
}

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth }: { acc: any[]; pathname: string; item: Item; depth: number }): any {
  const key = item.title + depth;

  const open = item.href && pathname.toLowerCase().includes(item.href.toLowerCase());
  if (item.items) {
    if (item.showItem) {
      acc.push(
        <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={!!open} title={item.title}>
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    }
  } else {
    if (item.showItem) {
      acc.push(
        <NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} open={!!open} />
      );
    }
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    zIndex: 30
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  },
  linkContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: 0
    }
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const { data: teamAndOrgs } = useCurrentUserTeamsAndOrgs();
  const adminOrgs =
    teamAndOrgs?.orgs.filter(o => compute.org.isOrgAdmin(teamAndOrgs?.orgs ?? [], getCurrentUserAccountId(), o.id)) ?? [];
  const isOrgAdmin = !!adminOrgs.length;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const sections: Section[] = [
    {
      subheader: translate({ defaultMessage: "My Tools" }),
      items: [
        {
          title: translate.common.Teams,
          href: "/app/teams",
          icon: ListIcon,
          showItem: true
        },
        {
          title: translate({ defaultMessage: "Directory" }),
          href: "/app/directory",
          icon: UsersIcon,
          showItem: isOrgAdmin
        },
        {
          title: translate({ defaultMessage: "Stats" }),
          icon: ActivityIcon,
          href: "/app/stats/dashboard",
          showItem: isOrgAdmin
        },
        {
          title: translate({ defaultMessage: "Team Trends" }),
          icon: TrendingUpIcon,
          href: "/app/stats/listTeamsWithStats",
          showItem: true
        },
        {
          title: translate({ defaultMessage: "Games" }),
          icon: DiscIcon,
          href: "/app/stats/games",
          showItem: true
        },
        {
          title: translate({ defaultMessage: "My Email" }),
          href: "/app/email",
          icon: MailIcon,
          showItem: true
        }
      ],
      showSection: true
    }
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column" className="pb-12">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex">
            <RouterLink to="/">
              <Logo variant="dark" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2} className={classes.linkContainer}>
          {sections
            .filter(i => i.showSection)
            .map(section => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items.filter(item => item.showItem),
                  pathname: location.pathname
                })}
              </List>
            ))}
          {adminOrgs.map(org => {
            const orgHasPaymentsEnabled = !!org.enabledFeatures?.payments;
            const hasViewFinancesPermission = !!compute.org.hasOrgViewFinanacesPermission({
              accountId: getCurrentUserAccountId(),
              org
            });

            return (
              <React.Fragment key={org.id}>
                <List
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {org.name}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: [
                      {
                        title: translate.common.Members,
                        href: `/app/org/${org.id}/members`,
                        icon: UsersIcon,
                        showItem: true
                      },
                      {
                        title: translate.common.Teams,
                        href: `/app/org/${org.id}/teams`,
                        icon: ListIcon,
                        showItem: true
                      },
                      {
                        title: translate.common.Seasons,
                        href: `/app/org/${org.id}/seasons`,
                        icon: Calendar,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate.common.Coupons,
                        href: `/app/org/${org.id}/coupons`,
                        icon: Percent,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate({ defaultMessage: "Registrations" }),
                        href: `/app/org/${org.id}/registrationDashboard`,
                        icon: ClipboardDocumentCheckIcon,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate({ defaultMessage: "Registration Packages" }),
                        href: `/app/org/${org.id}/registrationPackages`,
                        icon: ClipboardDocumentListIcon,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate({ defaultMessage: "Registration Questions" }),
                        href: `/app/org/${org.id}/registrationQuestions`,
                        icon: QuestionMarkCircleIcon,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate.common.PaymentPlans,
                        href: `/app/org/${org.id}/paymentPlans`,
                        icon: CreditCardIcon,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate.common.Invoices,
                        href: `/app/org/${org.id}/invoices`,
                        icon: ReceiptOutlined,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate.common.Payments,
                        href: `/app/org/${org.id}/payments`,
                        icon: DollarSign,
                        showItem: hasViewFinancesPermission && orgHasPaymentsEnabled
                      },
                      {
                        title: translate.common.TeamTags,
                        href: `/app/org/${org.id}/teamTags`,
                        icon: Tag,
                        showItem: true
                      },
                      {
                        title: translate.common.Settings,
                        href: `/app/org/${org.id}/settings`,
                        icon: Settings,
                        showItem: true
                      }
                    ],
                    pathname: location.pathname
                  })}
                </List>
              </React.Fragment>
            );
          })}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
